.post-title-image img {
    float: right;
    padding-bottom: 10px;
    padding-left: 10px;
    max-height: 250px;
}

.site-header {
    border-top: 0px;
}

pre > code {
    white-space: pre-wrap;
}
